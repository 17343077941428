import React from 'react';
import { Helmet } from 'react-helmet';

// Stateless component
function Montemar(props) {
  let deviceType;
  (props.isMobile) ? (deviceType = "mobile") : (deviceType = "desktop");

  function renderSideBar() {
    return (
      <div className={"sidebar-wrap " + deviceType}>
        <h1 className={"sidebar-h1-title " + deviceType}>ABOUT MONTEMAR</h1>
        <ul className={"sidebar-ul " + deviceType}>
          <li className="sidebar-active-li">Background</li>
          <li className="subsidiary-website-li"><a target="_blank" href="http://www.montemar.com.ph" rel="noopener noreferrer">Montemar Beach Club Website</a></li>
        </ul>
      </div>
    );
  }

  function renderContent() {
    return (
      <div className={"content-wrap " + deviceType}>
        <h2>Montemar Beach Club Inc.</h2>
        <div>
          <p className="content-p">Montemar Beach Club, Inc. was incorporated as a non-stock, non-profit corporation in 1978 and started operating the following year. The corporation has approximately 350 active members among its 8,000 proprietary members. It is majority-owned by the Philippine Communications Satellite Corporation and is open to active individual and corporate members, their guests and sponsored groups. The Club is ideal for rest, recreation, dining, conventions, parties and weddings.</p>
          <br />
          <p className="content-p">Located at Sitio Pasinay, Brgy. Pag-Asa, Bagac, Bataan, the Club boasts of a 12-hectare cove with a 500-meter stretch of powdery white sand beach facing Bagac Bay (West Philippine Sea). The Club has been regarded as “the most beautiful white-sand beach resort closest to Manila” as it is approximately 150km or a mere 2 ½ drive away from Metro Manila.</p>
          <br />
          <p className="content-p">Aside from the beach, the Club also has no less than three pools (the Member’s Pool, the Pavilion Pool and the Garden Pool) for the enjoyment of its members and guests. Various activities such as kayaking, jet skiing, horseback riding and fishing, to name a few, are offered at the Club.</p>
          <br />
          <p className="content-p">The Club has 89 rooms that are constantly being updated and renovated to provide ultimate comfort and ample space for the relaxation of its members and guests.</p>
          <br />
        </div>

        <h3 className="phc-contact">Contact</h3>
        <p className="content-p">Montemar Beach Club</p>
        <p className="content-p">Sitio Pasinay, Brgy. Pag-Asa</p>
        <p className="content-p">Bagac, Bataan, 2107, Philippines</p>
        <p className="content-p">T: +63 2 8811 5496 / +63 2 8892 6497 to 98 | F: +63 2 8811 5235</p>
        <p className="content-p">M: +63 917 527 1806</p>
        <p className="content-p">reservations.mbci@gmail.com</p>
        <a href="http://www.montemar.com.ph" target="_blank" className="subsidiary-website-content" rel="noopener noreferrer">www.montemar.com.ph/</a>
        <br/>
        <br/>

        <iframe className="montemar-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3861.258970561995!2d120.39240109983203!3d14.58431387108911!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3c653e1cb1605c62!2sMontemar+Beach+Club+Resort!5e0!3m2!1sen!2sph!4v1472146945532" frameBorder="0" style={{ border:"0" }} allowFullScreen title="montemar-map"></iframe><br />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Montemar Beach Club</title>
        <meta
          name="description"
          content="Montemar Beach Club Inc. is a private resort majority owned by Philcomsat"
        />
      </Helmet>

      <div className="montemar-container">
        <div className="hero-image-wrap">
          <img className={"hero-image " + deviceType} src={process.env.PUBLIC_URL + '/images/montemar2.jpg'} alt="Montemar Beach" />
        </div>

        <div className="body-wrap-outer">
          <div className={"body-wrap-inner " + deviceType}>
            {renderSideBar()}
            {renderContent()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Montemar;