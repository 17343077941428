import React from 'react';
import { Helmet } from 'react-helmet';

function Energy(props) {
  let deviceType;
  (props.isMobile) ? (deviceType = "mobile") : (deviceType = "desktop");


  function renderSideBar() {
    return (
      <div className={"sidebar-wrap " + deviceType}>
        <h1 className={"sidebar-h1-title " + deviceType}>ABOUT</h1>
        <ul className={"sidebar-ul " + deviceType}>
          <li className="sidebar-active-li">Renewable Energy</li>
          <li className="subsidiary-website-li"><a target="_blank" href="http://www.phc.com.ph" rel="noopener noreferrer">Philcomsat Holdings Website</a></li>
        </ul>
      </div>
    );
  }


  function renderContent() {
    return (
      <div className={"content-wrap " + deviceType}>
        <h2>Renewable Energy</h2>
        <p className="content-p">
          Land lease and/or co-venture projects - With vast landholdings in Bagac, 
          Bataan, Baras, Rizal, the PHILCOMSAT GROUP is currently exploring opportunities 
          to lease its properties, as well as considering joint ventures or equity 
          investments in the field of renewable energy. <br/>
          <br/>
          More information coming soon!
        </p>
        <br />
        <img className="solar-image2" src={process.env.PUBLIC_URL + '/images/energy2.jpg'} alt="Solar Panels" />
      </div>
    );
  }


  return (
    <>
      <Helmet>
        <title>Renewable Energy</title>
        <meta
          name="description"
          content="Renewable Energy projects under the Philcomsat Group"
        />
      </Helmet>

      <div className="energy-container">
        <div className="hero-image-wrap">
          <img className={"hero-image " + deviceType} src={process.env.PUBLIC_URL + '/images/home-body1-en.jpg'} alt="Solar Panels" />
        </div>

        <div className="body-wrap-outer">
          <div className={"body-wrap-inner " + deviceType}>
            {renderSideBar()}
            {renderContent()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Energy;