import React from 'react';
import { Helmet } from 'react-helmet';

// Stateless component
function PHC(props) {
  let deviceType;
  (props.isMobile) ? (deviceType = "mobile") : (deviceType = "desktop");

  function renderSideBar() {
    return (
      <div className={"sidebar-wrap " + deviceType}>
        <h1 className={"sidebar-h1-title " + deviceType}>ABOUT PHC</h1>
        <ul className={"sidebar-ul " + deviceType}>
          <li className="sidebar-active-li">Background</li>
          <li className="subsidiary-website-li"><a target="_blank" href="http://www.phc.com.ph" rel="noopener noreferrer">Philcomsat Holdings Website</a></li>
        </ul>
      </div>
    );
  }

  function renderContent() {
    return (
      <div className={"content-wrap " + deviceType}>
        <h2>Philcomsat Holdings Corporation (PHC)</h2>
        <div>
          <p className="content-p">Philcomsat Holdings Corporation (PHC), listed on the Philippine Stock Exchange (PSE), is an investment holding company that manages a portfolio by investing in the equities market, short- and medium-term instruments, and fixed-income securities.
           PHC, directly or through its wholly owned subsidiary, Philcomsat Management Enterprises, Inc. (PMEI), invests in operating companies with the objective of maximizing returns to the shareholders. 
           PHC is a subsidiary of Philippine Communications Satellite Corporation ("PHILCOMSAT") which owns seventy-nine percent (79%) of its outstanding capital stock.</p>
          <br />
        </div>

        <h3 className="phc-contact">Contact</h3>
        <p className="content-p">Philcomsat Holdings Corporation</p>
        <p className="content-p">12/F Telecom Plaza</p>
        <p className="content-p">316 Sen. Gil Puyat Avenue</p>
        <p className="content-p">Makati City, 1200 Metro Manila</p>
        <br />
        <p className="content-p">Tel: (632) 8815-8406</p>
        <p className="content-p">Fax: (632) 8817-9430</p>
        <p className="content-p">inquiry@phc.com.ph</p>
        <a href="http://www.phc.com.ph" target="_blank" className="subsidiary-website-content" rel="noopener noreferrer">www.phc.com.ph</a>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Philcomsat Holdings Corp</title>
        <meta
          name="description"
          content="Philcomsat Holdings Corporation (PHC) manages a portfolio by investing in the equities and fixed-income markets"
        />
      </Helmet>

      <div className="phc-container">
        <div className="hero-image-wrap">
          <img className={"hero-image " + deviceType} src={process.env.PUBLIC_URL + '/images/makati.jpg'} alt="Makati City" />
        </div>

        <div className="body-wrap-outer">
          <div className={"body-wrap-inner " + deviceType}>
            {renderSideBar()}
            {renderContent()}
          </div>
        </div>
      </div>
    </>
  );
}

export default PHC;


