import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/root'
import './index.css';
// import ReactGA from 'react-ga';
// import { createBrowserHistory } from 'history'
import Modal from 'react-modal';

// // Google Analytics Tracking ID
// const trackingId = "UA-81701265-1";   

// history.listen( location => {
//   ReactGA.set({ page: location.pathname }); // Update users current page
//   ReactGA.pageview(lcoation.pathname);      // Record a pageview for the given page
// });

// ENTRY FILE
// Wait for DOM to load, then load root component
document.addEventListener('DOMContentLoaded', ()=> {
  
  // Grab root element <div>
  const root = document.getElementById('root');

  // covid update modal
  Modal.setAppElement(root);

  // Load Root component in place of root element
  ReactDOM.render(<Root/>, root)
});