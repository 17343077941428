import React from 'react';
import { Link } from 'react-router-dom';


function Footer(props) {
  let deviceType;
  (props.isMobile) ? (deviceType = "mobile") : (deviceType = "desktop");

  return (
    <div className="footer-container">

      <div className="footer-top-wrap">
        <div className={"footer-top " + deviceType}>
          <div className="footer-top__boxes">
            <ul>
              {/* <li><Link onClick={() => window.scrollTo(0,0)} to={{ pathname: '/about', aboutProps: BACKGROUND }} className="title" >ABOUT</Link></li> */}
              <li><Link onClick={() => window.scrollTo(0,0)} to='/about' className="title" >ABOUT</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/about'>BACKGROUND</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/about/ownership'>OWNERSHIP</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/about/management-potc'>MANAGEMENT</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/about/permits'>REGULATORY PERMITS</Link></li>
            </ul>
          </div>

          <div className="footer-top__boxes">
            <ul>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/services' className="title" >SERVICES</Link></li>
              <li><Link onClick={() => window.scrollTo(0, 0)} to='/services/internet-vsat'>INTERNET BROADBAND VIA VSAT</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/services/lease-circuits'>LEASE CIRCUITS</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/services/aeronautical'>AERONAUTICAL</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/services/maritime'>MARITIME</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/services/broadcast-tv'>BROADCAST TV</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/services/disaster-management'>DISASTER MANAGEMENT</Link></li>
            </ul>
          </div>

          <div className="footer-top__boxes">
            <ul>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/'>HOME</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/services'>SERVICES</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/phc'>PHILCOMSAT HOLDINGS CORP</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/montemar'>MONTEMAR BEACH CLUB</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/press'>PRESS</Link></li>
              <li><Link onClick={() => window.scrollTo(0,0)} to='/contact'>CONTACT</Link></li>
            </ul>
          </div>

          <div className="footer-top__boxes">
            <div className="footer-logo">PHILCOMSAT</div>
            <p>12TH FLR., TELECOM PLAZA</p>
            <p>316 SEN. GIL PUYAT AVENUE</p>
            <p>MAKATI CITY, 1200 PHILIPPINES</p>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom-wrap">
        <div className="footer-bottom">
          <div className="footer-inner">
            <div className="footer-companies">PHILCOMSAT GROUP OF COMPANIES</div>
            <div className="footer-companies-list">
              <ul>
                <li><Link onClick={() => window.scrollTo(0, 0)} to="/about">PHILCOMSAT</Link></li>
                <li><a target="_blank" rel="noopener noreferrer" href="http://www.montemar.com.ph/">MONTEMAR</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/">PHC</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://professionalstocktransfer.com/">PSTI</a></li>
              </ul>
            </div>
            <div className="copyright">© 2020 PHILIPPINE COMMUNICATIONS SATELLITE CORP.</div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Footer;