import React from 'react';
import { NavLink } from 'react-router-dom';
import HamburgerButton from './SideDrawer/HamburgerButton';
import ReactModal from 'react-modal';

// Tutorials:
// https://www.youtube.com/watch?v=F0uED-w9E48          left side navbar, responsive, hamburger, html/css, no top nav
// https://www.youtube.com/watch?v=8QKOaTYvYUA          top navbar, responsive, hamburger, html/css, yes top nav w/ logo
// https://www.youtube.com/watch?v=6pidsgeLLzE          top navbar, responsive, hamburger, html/css, no top nav
// https://www.youtube.com/watch?v=xMTs8tAapnQ&t=600s   top navbar, responsive, hamburger, html/css, yes top nav 
// https://www.youtube.com/watch?v=wpGNFGqNfdU          !! left side navbar, responsive, hamburger, html/css, yes top nav w/ logo


// https://www.youtube.com/watch?v=l6nmysZKHFU          left side navbar, responsive, hamburger, REACT, html/css, yes top nav w/ logo
// function NavBar(props) {
class NavBar extends React.Component {
  constructor(props) {
    super(props);

    (props.isMobile) ? (this.deviceType = "mobile") : (this.deviceType = "desktop"); 

    this.state = {
      modalIsOpen: true      // modal for covid update
    }
  }

  closeCovidModal = () => {
    return (this.setState({
      modalIsOpen: false
    }));
  }

  render() {
    return (
      <div className={"navbar " + this.deviceType}> 
  
        <nav className={"navbar__top " + this.deviceType}>
          <HamburgerButton 
            click={this.props.drawerClickHandler}
            deviceType={this.deviceType}
          />
  
          <div className={"navbar__logo " + this.deviceType}>
            <NavLink to="/">
              <img src={process.env.PUBLIC_URL + '/images/logo2.gif'} alt="logo"/>
            </NavLink>
          </div>
  
          <div className={"navbar__top-items " + this.deviceType}>
            <ul>
              {/* <li><NavLink activeClassName="active" to={{ pathname: '/about', aboutProps: BACKGROUND }}>ABOUT</NavLink></li>
              <li><NavLink activeClassName="active" to={{ pathname: '/services', aboutProps: DEFAULT }}>SERVICES</NavLink></li> */}
              <li><NavLink activeClassName="active" to='/about'>ABOUT</NavLink></li>
              <li><NavLink activeClassName="active" to='/services'>SERVICES</NavLink></li>
              <li><NavLink activeClassName="active" to="/phc">PHILCOMSAT HOLDINGS CORP</NavLink></li>
              <li><NavLink activeClassName="active" to="/montemar">MONTEMAR BEACH CLUB</NavLink></li>
              <li><NavLink activeClassName="active" to="/press">PRESS</NavLink></li>
              <li><NavLink activeClassName="active" to="/contact">CONTACT</NavLink></li>
            </ul>
          </div>
  
        </nav>

        <div id="navbar-blue-bottom">
          <p className="covid-update-navbar" onClick={() => this.setState({ modalIsOpen: true })}>COVID UPDATE</p>
          <ReactModal
            isOpen={this.state.modalIsOpen}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={this.closeCovidModal}
            contentLabel="Covid Update Modal"
            className={"covid-modal " + this.deviceType}
            overlayClassName={"covid-overlay " + this.deviceType}
          >
            <button className={"close-covid-modal " + this.deviceType} onClick={this.closeCovidModal}>X</button>
            <div className={"covid-update " + this.deviceType}>

              <p>To our customers, partners, and stakeholders:</p>
              <br />
              <p>The constantly changing levels of community quarantine in different parts of the archipelago, while a necessary measure to fight COVID-19 and keep all Filipinos safe and healthy, have proven to be a challenge.</p>
              <br />
              <p>To protect our team members, most of our office staff work from home supported by a skeletal force so we can continue to keep you connected. Our services to the Armed Forces of the Philippines, the Philippine Navy, the Philippine Coast Guard, and the Office of Civil Defense remain operative. The 389 sites installed and activated under the Free Wi-Fi program of the government likewise remain operative. Due to travel restrictions imposed by LGUs, we will carefully assess and evaluate all requests for field visits to protect both our customers and our field engineers. While we may be unable to accommodate requests for installation at this time due to these restrictions, we may be able to provide alternative options in the event of loss of connectivity of existing sites.</p>
              <br />
              <p>Rest assured that we are doing our best to provide the service you have entrusted us to deliver during these difficult times.</p>
              <br />
              <p>PHILCOMSAT MANAGEMENT</p>
            </div>
          </ReactModal>
        </div>
      </div>
    );
  }
}

export default NavBar;










