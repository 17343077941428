import React from 'react';
import { Helmet } from 'react-helmet';

// Used as values for state
const BACKGROUND = 'Background';
const COMPANIES = 'Philcomsat Group of Companies';
const MANAGEMENT_POTC = 'Philippine Overseas Telecommunications Corp. (POTC)';
const MANAGEMENT_PHILCOMSAT = 'Philippine Communications Satellite Corp. (PHILCOMSAT)';
const MANAGEMENT_PHC = 'Philcomsat Holdings Corp. (PHC)';
const MANAGEMENT_MBCI = 'Montemar Beach Club Inc. (MBCI)';
const PERMITS = 'Regulatory Permits';


class About extends React.Component {
  constructor(props) {
    super(props);
    (props.isMobile) ? (this.deviceType = "mobile") : (this.deviceType = "desktop");

    // Props being passed through routing in Root Component
    let h2TitleValue = props.aboutProps || BACKGROUND;
    let showManagement = false;
    if (props.showManagement) showManagement = true;

    this.state = {
      h2Title: h2TitleValue,
      showManagement: showManagement,
    };
  }


  componentDidUpdate() {
    let newState = this.props.aboutProps;
    let showManagement = false;
    if (this.props.showManagement) showManagement = true;

    if (newState !== this.state.h2Title) {
      this.setState({
        h2Title: newState,
        showManagement: showManagement
      });
    }
  }
 

  // ? Why need to bind if:   <li onClick={this.setText}>
  //   but not if we have:    <li onClick={() => this.setText()}>
  // https://medium.com/front-end-weekly/do-i-still-need-to-bind-react-functions-in-2019-6d0fe72f40d7
  renderSideBar() {
    let managementSideBar;
    if (this.state.showManagement) {
      managementSideBar = (
        <>
          <li 
            className={this.state.h2Title === MANAGEMENT_POTC ? "management-li active first" : "management-li first"} 
            onClick={() => {
                this.props.history.push('/about/management-potc');
                // return this.setState({ h2Title: MANAGEMENT_POTC })
              }
            }
          >Philippine Overseas Telecommunications Corp. (POTC)</li>

          <li 
            className={this.state.h2Title === MANAGEMENT_PHILCOMSAT ? "management-li active" : "management-li"}
            onClick={() => {
                this.props.history.push('/about/management-philcomsat');
                // return this.setState({h2Title: MANAGEMENT_PHILCOMSAT})
              }
            }
          >Philippine Communications Satellite Corp. (PHILCOMSAT)</li>

          <li 
            className={this.state.h2Title === MANAGEMENT_PHC ? "management-li active" : "management-li"}
            onClick={() => {
                this.props.history.push('/about/management-phc');
                // return this.setState({h2Title: MANAGEMENT_PHC})
              }
            }
          >Philcomsat Holdings Corp. (PHC)</li>

          <li 
            className={this.state.h2Title === MANAGEMENT_MBCI ? "management-li active last" : "management-li last"} 
            onClick={() => {
                this.props.history.push('/about/management-mbci');
                // return this.setState({ h2Title: MANAGEMENT_MBCI })
              }
            }
          >Montemar Beach Club Inc. (MBCI)</li>
        </>
      );
    }

    return (
      <div className={"sidebar-wrap " + this.deviceType}>
        <h1 className={"sidebar-h1-title " + this.deviceType}>ABOUT US</h1>
        <ul className={"sidebar-ul " + this.deviceType}>
          <li 
            className={this.state.h2Title === BACKGROUND ? "sidebar-active-li" : "sidebar-inactive-li"} 
            onClick={() => {
                this.props.history.push('/about')
                // return this.setState({ h2Title: BACKGROUND, showManagement: false })
              }
            }
          >Background</li>    

          <li
            className={this.state.h2Title === COMPANIES ? "sidebar-active-li" : "sidebar-inactive-li"}  
            onClick={() => {
                this.props.history.push('/about/ownership');
                // return this.setState({h2Title: COMPANIES, showManagement: false})
              }
            }
          >Philcomsat Group of Companies</li>
          
          <li 
            className={this.state.showManagement ? "sidebar-active-li" : "sidebar-inactive-li"}  
            onClick={() => {
              this.props.history.push('/about/management-potc');
              // return this.setState({h2Title: MANAGEMENT_POTC, showManagement: true})
              }
            }
          >Management</li>

          {managementSideBar}

          <li 
            className={this.state.h2Title === PERMITS ? "sidebar-active-li" : "sidebar-inactive-li"}
            onClick={() => {
                this.props.history.push('/about/permits');
                // return this.setState({h2Title: PERMITS, showManagement: false})
              }
            }
          >Regulatory Permits</li>
        </ul>
      </div>
    );
  }


  renderContent() {
    let content;

    // Depending on state, render appropriate side content
    switch (this.state.h2Title) {
      case (BACKGROUND):
        content = this.renderBackground();
        break;
      case (COMPANIES):
        content = this.renderCompanies();
        break;
      case (MANAGEMENT_POTC):
        content = this.renderManagementPOTC();
        break;
      case (MANAGEMENT_PHILCOMSAT):
        content = this.renderManagementPHILCOMSAT();
        break;
      case (MANAGEMENT_PHC):
        content = this.renderManagementPHC();
        break;
      case (MANAGEMENT_MBCI):
        content = this.renderManagementMBCI();
        break;
      case (PERMITS):
        content = this.renderPermits();
        break;
      default:
        content = BACKGROUND;
    }

    return(
      <div className={"content-wrap " + this.deviceType}>
        <h2>{this.state.h2Title}</h2>
        {content}
      </div>
    );
  }


  renderBackground() {
    return(
      <>
        <Helmet>
          <title>About Us - Philcomsat</title>
          <meta
            name="description"
            content="We are a leading Satellite Broadband VSAT Internet Provider for the Philippines. We are a satellite telecommunications company dedicated to providing a medium for people to communicate within the archipelago and across oceans and continents."
          />
        </Helmet>

        <div>
          <p className="content-p">The Philippine Communications Satellite Corporation (PHILCOMSAT), a wholly-owned subsidiary of the Philippine Overseas Telecommunication Corporation (POTC), is an enfranchised and licensed Philippine public telecommunications entity (PTE), 35% owned by the Republic of the Philippines. PHILCOMSAT, as the Philippine satellite pioneer for almost four decades, made the world smaller for Filipinos and impacted the lives of every person or enterprise by providing a medium for people to communicate within the archipelago and across oceans and continents, through voice/fax, data, audio, video, broadcast TV, internet or IP multimedia applications.</p>
          <br/>
          <p className="content-p">At present, PHILCOMSAT is positioned to benefit from the convergence of global communications and internet protocol technologies and applications. IP communication pervades every facet of industrial, commercial and social activity. PHILCOMSAT’s IP communication services encompass a broad range of applications from the traditional and robust C-band Very Small Aperture Terminals (Vsat) to the new Ka-, Ku-band High Throughput Satellites (HTS), which can provide more than 1Gigabit per second capacity. These solutions are also available as redundancy and backhaul to existing GSM/3G/4G/LTE Networks, as well as the transport of internet broadband and Virtual Private Network (VPN) requirements for enterprise, government and non-government organizations operating within and outside the Philippines.</p>
          <br/>
          <p className="content-p">In addition to its telecommunications mega-franchise, PHILCOMSAT also maintains licenses in VSAT, value-added services (VAS), International Satellite Carrier (ISC), Voice Over Internet Protocol (VOIP) and INMARSAT.</p>
          <br/>
          <p className="content-p">Affiliate Companies</p>
          <p className="content-p">PHILCOMSAT is owned by PHILIPPINE OVERSEAS TELECOMMUNICATIONS COPRORATION (POTC). PHILCOMSAT owns 79% of PHILCOMSAT HOLDINGS CORPORATION (PHC) - a company listed in the Philippine Stock Exchange (PSE). PHILCOMSAT also owns 68%, indirectly and directly, of MONTEMAR BEACH CLUB.</p>
        </div>
      </>
    );
  }


  renderCompanies() {
    return(
      <>
        <Helmet>
          <title>Philcomsat Group of Companies - Ownership</title>
          <meta
            name="description"
            content="Companies owned by Philcomsat"
          />
        </Helmet>

        <p className="content-p">PHILCOMSAT is owned by Philippine Overseas Telecommunications Corp. (POTC). PHILCOMSAT owns 79% of Philcomsat Holdings Corp. (PHC) - a company listed in the Philippine Stock Exchange (PSE). PHILCOMSAT also owns 68% of Montemar Beach Club, Inc. (MBCI) indirectly and directly.</p>
        <br/>
        <img className="ownership-chart-img" src={process.env.PUBLIC_URL + '/images/about-ownership-chart.jpg'} alt="Company Ownership Structure"/>
        {/* <img className="ownership-chart-img" src='http://www.philcomsat.com.ph/images/about-ownership-chart.jpg' alt="Company Ownership Structure"/> */}
      </>
    );
  }
  
  
  renderManagementPOTC() {
    return(
      <>
        <Helmet>
          <title>Management - POTC</title>
          <meta
            name="description"
            content="Board of Directors and Officers of Philippine Overseas Telecommunications Corp. (POTC)"
          />
        </Helmet>

        <div className="board-officers-wrap">
          <div className={"board-wrap " + this.deviceType}>
            <h3 className="board">BOARD OF DIRECTORS</h3>
            <ul className="board-ul">
              <li>Katrina C. Ponce-Enrile</li>
              <li>Lin I. Bildner</li>
              <li>Marietta K. Ilusorio</li>
              <li>Pablo L. Lobregat</li>
              <li>Santiago J. Ranada</li>
              <li>Bayani H. Agabin</li>
            </ul>
          </div>

          <div className={"officers-wrap " + this.deviceType}>
            <h3 className="officers">OFFICERS</h3>
            <ul className="officers-ul">
              <li className="officer-li">Santiago J. Ranada</li>
              <li className="title-li">Chairman</li>

              <li className="officer-li">Lin I. Bildner</li>
              <li className="title-li">Vice Chairman</li>

              <li className="officer-li">Katrina C. Ponce-Enrile</li>
              <li className="title-li">President and CEO</li>

              <li className="officer-li">Marietta K. Ilusorio</li>
              <li className="title-li">Executive Vice President</li>

              <li className="officer-li">Timoteo C. Basit</li>
              <li className="title-li">Treasurer</li>

              <li className="officer-li">Victoria C. De los Reyes</li>
              <li className="title-li">Corporate Secretary</li>
            </ul>
          </div>
        </div>
      </>
    );
  }


  renderManagementPHILCOMSAT() {
    return (
      <>
        <Helmet>
          <title>Management - Philcomsat</title>
          <meta
            name="description"
            content="Board of Directors and Officers of Philippine Communications Satellite Corp. (PHILCOMSAT)"
          />
        </Helmet>

        <div className="board-officers-wrap">
          <div className={"board-wrap " + this.deviceType}>
            <h3 className="board">BOARD OF DIRECTORS</h3>
            <ul className="board-ul">
              <li>Santiago J. Ranada</li>
              <li>Abraham R. Abesamis</li>
              <li>Lin I. Bildner</li>
              <li>Marietta K. Ilusorio</li>
              <li>Katrina C. Ponce-Enrile</li>
              <li>Pablo L. Lobregat</li>
              <li>Bayani H. Agabin</li>
              <li>Raymundo DV. Elefante</li>
              <li>Samuel C. Uy</li>
            </ul>
          </div>

          <div className={"officers-wrap " + this.deviceType}>
            <h3 className="officers">OFFICERS</h3>
            <ul className="officers-ul">
              <li className="officer-li">Santiago J. Ranada</li>
              <li className="title-li">Chairman</li>

              <li className="officer-li">Abraham R. Abesamis</li>
              <li className="title-li">Vice Chairman</li>

              <li className="officer-li">Lin I. Bildner</li>
              <li className="title-li">President and CEO</li>

              <li className="officer-li">Marietta K. Ilusorio</li>
              <li className="title-li">Vice President</li>

              <li className="officer-li">Bernadette Y. Blanco</li>
              <li className="title-li">Vice President, Legal & Administrative Matters</li>

              <li className="officer-li">Katrina C. Ponce-Enrile</li>
              <li className="title-li">Treasurer</li>

              <li className="officer-li">Manolita L. Morales</li>
              <li className="title-li"> Assistant Treasurer</li>

              <li className="officer-li">John Benedict L. Sioson</li>
              <li className="title-li">Corporate Secretary</li>
            </ul>
          </div>
        </div>
      </>
    );
  }


  renderManagementPHC() {
    return (
      <>
        <Helmet>
          <title>Management - PHC</title>
          <meta
            name="description"
            content="Board of Directors and Officers of Philcomsat Holdings Corp. (PHC)"
          />
        </Helmet>

        <div className="board-officers-wrap">
          <div className={"board-wrap " + this.deviceType}>
            <h3 className="board">BOARD OF DIRECTORS</h3>
            <ul className="board-ul">
              <li>Katrina C. Ponce-Enrile</li>
              <li>Lin I. Bildner</li>
              <li>Daniel C. Gutierrez</li>
              <li>Pablo L. Lobregat</li>
              <li>Marietta K. Ilusorio</li>
              <li>Prudencio C. Somera, Jr.</li>
              <li>Santiago J. Ranada</li>
              <li>Victoria C. de los Reyes</li>
              <li>Jose Ramon Ozamiz (Ind.)</li>
              <li>Julie Y. Daza (Ind.)</li>
              <li>Oliverio L. Laperal, Jr. (Ind.)</li>
            </ul>
          </div>

          <div className={"officers-wrap " + this.deviceType}>
            <h3 className="officers">OFFICERS</h3>
            <ul className="officers-ul">
              <li className="officer-li">Santiago J. Ranada</li>
              <li className="title-li">Chairman</li>

              <li className="officer-li">Katrina C. Ponce-Enrile</li>
              <li className="title-li">President and CEO</li>

              <li className="officer-li">Victoria C. de los Reyes</li>
              <li className="title-li">Vice President</li>

              <li className="officer-li">Lin I. Bildner</li>
              <li className="title-li">CFO and Treasurer</li>

              <li className="officer-li">Manolita L. Morales</li>
              <li className="title-li"> Assistant Treasurer</li>

              <li className="officer-li">Bernadette Y. Blanco</li>
              <li className="title-li">Compliance Officer</li>

              <li className="officer-li">John Benedict L. Sioson</li>
              <li className="title-li">Corporate Secretary/Corporate Information Officer</li>

              <li className="officer-li">Jose Pio J. Seva</li>
              <li className="title-li">Assistant Corporate Secretary/Investor Relations Officer</li>
            </ul>
          </div>
        </div>
      </>
    );
  }


  renderManagementMBCI() {
    return (
      <>
        <Helmet>
          <title>Management - Montemar</title>
          <meta
            name="description"
            content="Board of Directors and Officers of Montemar Beach Club Inc. (MBCI)"
          />
        </Helmet>

        <div className="board-officers-wrap">
          <div className={"board-wrap " + this.deviceType}>
            <h3 className="board">BOARD OF DIRECTORS</h3>
            <ul className="board-ul">
              <li>Lin I. Bildner</li>
              <li>Katrina C. Ponce-Enrile</li>
              <li>Marietta K. Ilusorio</li>
              <li>Lorna P. Kapunan</li>
              <li>Manolita L. Morales</li>
              <li>Santiago J. Ranada</li>
              <li>Julie Y. Daza</li>
              <li>Victoria C. De los Reyes</li>
            </ul>
          </div>

          <div className={"officers-wrap " + this.deviceType}>
            <h3 className="officers">OFFICERS</h3>
            <ul className="officers-ul">
              <li className="officer-li">Lin I. Bildner</li>
              <li className="title-li">Chairman</li>

              <li className="officer-li">Katrina C. Ponce-Enrile</li>
              <li className="title-li">President and CEO</li>

              <li className="officer-li">Bernadette Y. Blanco</li>
              <li className="title-li">Vice President</li>

              <li className="officer-li">Timoteo C. Basit</li>
              <li className="title-li">Treasurer</li>

              <li className="officer-li">John Benedict L. Sioson</li>
              <li className="title-li">Corporate Secretary</li>
            </ul>
          </div>
        </div>
      </>
    );
  }
  
  
  renderPermits() {
    return(
      <>
        <Helmet>
          <title>Regulatory Permits - Philcomsat</title>
          <meta
            name="description"
            content="Regulatory Permits held by Philcomsat"
          />
        </Helmet>

        <img src={process.env.PUBLIC_URL + '/images/about-congress_logo.png'} alt="Congress Logo"/>
        <p className="content-p">FRANCHISE GRANTED BY THE CONGRESS OF THE REPUBLIC OF THE PHILIPPINES (salient provision of RA 11226)</p>
        <br/>
        <p className="content-p">Republic Act 11226 – “This franchise include the right and privilege of cable and wireless operations, such as telephone, mobile, cellular and wired or wireless telecommunications systems, fiber optics, multi-channel transmission distribution system, satellite transmit, receive and other telecommunications systems and their value-added services, control signals, audio and video, information service bureau and other telecommunications systems/technologies as are at present available or be made available through technological/technical advances or innovations in the future, and to purchase any or all of the equipment and materials needed in its operations; and to establish, construct, maintain, operate, own, manage, lease and purchase transmitting and receiving stations, switching stations and gateway facilities, for local and international services, lines, cables or systems as may be convenient to efficiently carry out the purpose of this franchise. The grantee, its successors or assignees, are further authorized to connect and keep connected its telecommunications systems in the Philippines and other countries and territories. The grantee shall be authorized to lease or acquire facilities, channels, or circuits in the pursuance of its business.”</p>
        <br/>
        <p className="content-p">PHILCOMSAT'S Franchise is valid up to March 30, 2044.</p>
        <br/>
        <br/>
        <img src={process.env.PUBLIC_URL + '/images/about-ntc_logo.png'} alt="NTC Logo"/>
        <p className="content-p">PROVISIONAL AUTHORITIES/PERMITS ISSUED by the NATIONAL TELECOMMUNICATIONS COMMISSION (NTC)</p>
        <br/>
        <ul className="content-p">
          <li>1. Very Small Aperture Terminal (VSAT) (Domestic and International)</li>
          <li>2. International Satellite Carrier (ISC)</li>          
          <li>3. Value-Added Services (VAS) Provider</li>
          <li>4. Voice Over Internet Protocol (VoIP) Service Provider</li>
          <li>5. INMARSAT</li>
        </ul>
      </>
    );
  }

  
  render() {
    return (
      <div className="about-container">
        <div className="hero-image-wrap">
          <img className={"hero-image " + this.deviceType} src={process.env.PUBLIC_URL + '/images/about-earth.jpg'} alt="earth from space"/>
          <div className="hero-image-text-about-wrap">
            <div className={"hero-image-text-about " + this.deviceType}>PHILCOMSAT is a telecommunications company dedicated to providing a medium for people to communicate within the archipelago and across oceans and continents.</div>
          </div>
        </div>

        <div className="body-wrap-outer">
          <div className={"body-wrap-inner " + this.deviceType}>
            {this.renderSideBar()}
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}


export default About;