import React from 'react';
import CrossfadeImage from 'react-crossfade-image';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import ReactModal from 'react-modal';
// https://www.npmjs.com/package/react-crossfade-image
// https://github.com/khanglu/react-crossfade-image/blob/master/example/App.js


const AERONAUTICAL = 'Aeronautical Communications';
const INTERNET_VSAT = 'Internet Broadband via VSAT';
const DISASTER_MANAGEMENT = 'Disaster Management';

const images = [
  // process.env.PUBLIC_URL + '/images/home-body1-covid.png',      // covid update
  process.env.PUBLIC_URL + '/images/home-body1-pinugay.jpg',
  process.env.PUBLIC_URL + '/images/home-body1-en.jpg',
  process.env.PUBLIC_URL + '/images/home-body1-phc.jpg',
  process.env.PUBLIC_URL + '/images/home-body1-mon.jpg',
];

const imageAltTags = [
  'Pinugay Satellite Earth Station',
  'Solar Panels',
  'Philippine Stock Exchange',
  'Montemar Beach'
];

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state ={
      imageIndex: 0,
      // modalIsOpen: true      // modal for covid update
    }

    this.interval = false;
    this.changeImage = this.changeImage.bind(this);

    // Preload images
    images.forEach(url => {
      const img = new Image();
      img.src = url;
    });

    // Set html <title> tag in <head>
    // document.title = "Philcomsat - Philippine Communications Satellite Corporation";
  }


  componentDidMount() {
    this.interval = window.setInterval(this.changeImage, 6000);
  }

  componentWillUnmount() {
    if (this.interval) window.clearInterval(this.interval);
  }


  changeImage() {
    if (this.state.imageIndex === images.length - 1) {
      this.setState({ imageIndex: 0 });
    } else {
      this.setState({ imageIndex: this.state.imageIndex + 1 });
    }
  }

  changeImageIndex(idx) {
    this.setState({
      imageIndex: idx
    });
  }

  // test = () => {   // covid update
  //   debugger
  // }
  // Covid update modal
  // closeCovidModal = () => {
  //   return (this.setState({
  //     modalIsOpen: false
  //   }));
  // }


  render() {
    let deviceType, carouselTitle;
    (this.props.isMobile) ? (deviceType = "mobile") : (deviceType = "desktop"); 

    const bottomTabs = [
      // { url: '', tabName: 'Covid Update' },                       // covid update
      { url: 'services', tabName: 'Telecommunications' },
      { url: 'energy', tabName: 'Renewable Energy' },
      { url: 'phc', tabName: 'Philcomsat Holdings Corp' },
      { url: 'montemar', tabName: 'Montemar Beach Club' },
    ];

    // Set carousel tab top title
    carouselTitle = bottomTabs[this.state.imageIndex].tabName;
    if (carouselTitle === "Telecommunications") carouselTitle = "Satellite Telecommunications";

    // Dynmaically generate <li>'s for carousel tab bottom
    // One Li className will be different depending on state
    let bottomTabListItems = bottomTabs.map( (tab, idx)=> {
      if (this.state.imageIndex === idx) {
        return (
          <li 
            key={idx} className={"carousel-tabs__li hidden"}
            onClick={() => this.changeImageIndex(idx)}
          >
            <NavLink to={`/${tab.url}`}>{tab.tabName}</NavLink>
          </li>
        );
      } else {
        return (
          <li 
            key={idx} className={"carousel-tabs__li"}
            onClick={() => this.changeImageIndex(idx)}
          >
            <NavLink to={`/${tab.url}`}>{tab.tabName}</NavLink>
          </li>
        );
      }
    });

    // let covidUpdate;
    // if (this.state.imageIndex === 0) {
    //   covidUpdate = (
    //     <div className={"covid-update " + deviceType}>
    //       <p>To our customers, partners, and stakeholders:</p>
    //       <br/>
    //       <p>It has been almost two weeks since the enhanced community quarantine in Luzon was quickly put into effect, an unexpected but necessary measure to fight COVID-19 and keep all Filipinos safe and healthy. </p>
    //       <br/>
    //       <p>To protect our team members while assuring continuous service, most of our office staff work from home supported by a skeletal force so we can continue to keep you connected.  Our services to the Armed Forces of the Philippines, the Philippine Navy, the Philippine Coast Guard and the Office of Civil Defense remain operative.  The 108 sites installed and activated under the Free Wi-Fi program of the government likewise remain operative.  Due to travel restrictions, we will carefully assess and evaluate any requests for field visits in order to protect both our customers and our field engineers.  While we may be unable to accommodate requests for installation at this time due to these restrictions, we may be able to provide alternative options in the event of loss of connectivity of existing sites.</p>
    //       <br/>
    //       <p>Rest assured that we are doing our best to provide the service you have entrusted us to deliver during these difficult times.</p>
    //       <br/>
    //       <p>PHILCOMSAT MANAGEMENT</p> 
    //     </div>
    //   );
    // }

    return (
      <>
        <Helmet>
          <title>Philcomsat - Philippine Communications Satellite Corporation</title>
          <meta
            name="description"
            content="We are a leading Satellite Broadband VSAT Internet Provider for the Philippines. We are a satellite telecommunications company dedicated to providing a medium for people to communicate within the archipelago and across oceans and continents."
          />
        </Helmet>

        {/* COVID UPDATE MODAL */}
        {/* <ReactModal
          isOpen={this.state.modalIsOpen}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={this.closeCovidModal}
          contentLabel="Covid Update Modal"
          className="covid-modal"
          overlayClassName="covid-overlay"
        >
          <button className="close-drawer" onClick={this.closeCovidModal}>X</button>
          <div className={"covid-update " + deviceType}>
            <p>To our customers, partners, and stakeholders:</p>
            <br />
            <p>It has been almost two weeks since the enhanced community quarantine in Luzon was quickly put into effect, an unexpected but necessary measure to fight COVID-19 and keep all Filipinos safe and healthy. </p>
            <br />
            <p>To protect our team members while assuring continuous service, most of our office staff work from home supported by a skeletal force so we can continue to keep you connected.  Our services to the Armed Forces of the Philippines, the Philippine Navy, the Philippine Coast Guard and the Office of Civil Defense remain operative.  The 108 sites installed and activated under the Free Wi-Fi program of the government likewise remain operative.  Due to travel restrictions, we will carefully assess and evaluate any requests for field visits in order to protect both our customers and our field engineers.  While we may be unable to accommodate requests for installation at this time due to these restrictions, we may be able to provide alternative options in the event of loss of connectivity of existing sites.</p>
            <br />
            <p>Rest assured that we are doing our best to provide the service you have entrusted us to deliver during these difficult times.</p>
            <br />
            <p>PHILCOMSAT MANAGEMENT</p>
          </div>
        </ReactModal> */}

        <div className="home-container">

          <div className={"carousel " + deviceType}>
            <div className={"slide " + deviceType}>
              <CrossfadeImage 
                class={"slide-img " + deviceType}
                src={images[this.state.imageIndex]}
                alt={imageAltTags[this.state.imageIndex]}
                duration={3000}
                timingFunction={"ease-out"}
                // style={{ 
                //   maxWidth:'100%', 
                //   maxHeight:'100%',
                //   position: 'absolute',
                //   height: '100%',
                //   width: '100%'
                // }}
                style={{
                  position: 'absolute',
                  maxWidth: 'initial',
                }}
                containerClass={"slide-img " + deviceType}
              />
            </div>
            
            {/* {covidUpdate} */}

            <div className="carousel-bottom">
              <div className={"carousel-title-wrap " + deviceType}>
                <div className={"carousel-title " + deviceType}>{carouselTitle}</div>
              </div>
              <ul className={"carousel-tabs " + deviceType}>
                {bottomTabListItems}
              </ul>
            </div>
          </div>

          <div className="gradient-homepage"/>

          <h2 className="featured-services-title">FEATURED TELECOMMUNICATIONS SERVICES</h2>

          <div className="featured-services">
            <div className="services">
            <NavLink onClick={() => window.scrollTo(0, 0)} to={{ pathname: '/services/disaster-management', aboutProps: DISASTER_MANAGEMENT }}>
                <div>Disaster Management Solutions</div>
                <img src={process.env.PUBLIC_URL + '/images/home-section2-flyawaykit.png'} alt="disaster management solutions"/>
              </NavLink>
            </div>

            <div className="services">
              <NavLink onClick={() => window.scrollTo(0,0)} to={{ pathname: '/services/aeronautical', aboutProps: AERONAUTICAL }}>
                <div>Aeuronautical & Maritime Services</div>
                <img src={process.env.PUBLIC_URL + '/images/home-section2-c130_plane.png'} alt="aeronautical services" />
              </NavLink>
            </div>

            <div className="services">
            <NavLink onClick={() => window.scrollTo(0, 0)} to={{ pathname: '/services/internet-vsat', aboutProps: INTERNET_VSAT }}>
                <div>Internet broadband via VSAT</div>
                <img src={process.env.PUBLIC_URL + '/images/home-section2-vsat_internet.png'} alt="internet broadband via VSAT" />
              </NavLink>
            </div>
          </div>
          {/* {this.test()} */}
        </div>
      </>
    );
  }
}


export default Home;