import React from 'react';


const HamburgerButton = props => {
  return (
    // <button className="hamburger-button" onClick={props.click}>
    <button className={"hamburger-button " + props.deviceType} onClick={props.click}>
      <div className="hamburger-button__line"></div>
      <div className="hamburger-button__line"></div>
      <div className="hamburger-button__line"></div>
    </button>
  );
}

export default HamburgerButton;