import React from 'react';
import { Helmet } from 'react-helmet';

// Stateless component
function Contact(props) {
  let deviceType;
  (props.isMobile) ? (deviceType = "mobile") : (deviceType = "desktop");

  function renderSideBar() {
    return (
      <div className={"sidebar-wrap-contact " + deviceType}>
        <h1 className={"sidebar-h1-title " + deviceType}>CONTACT US</h1>
        <ul className={"sidebar-ul " + deviceType}>
          <li className="sidebar-contact-li">PHILCOMSAT</li>
          <li className="sidebar-address-li">
            12th Flr., Telecom Plaza <br/>
            316 Sen. Gil Puyat Avenue, <br />
            Makati City, 1200 Philippines <br />
            Tel:  (+632) 8815-8406 <br />
            Fax: (+632) 8817-9430  <br />
            inquiry@philcomsat.com.ph <br/>
            <a href="https://www.philcomsat.com.ph">www.philcomsat.com.ph</a> 
          </li>

          <li className="sidebar-contact-li">PHILCOMSAT HOLDINGS CORP</li>
          <li className="sidebar-address-li">
            12th Flr., Telecom Plaza  <br />
            316 Sen. Gil Puyat Avenue,  <br />
            Makati City, 1200 Philippines <br />
            Tel:  (+632) 8815-8406 <br />
            Fax: (+632) 8817-9430  <br />
            inquiry@phc.com.ph <br />
            <a href="http://www.phc.com.ph">www.phc.com.ph</a>   
          </li>

          <li className="sidebar-contact-li">MONTEMAR BEACH CLUB</li>
          <li className="sidebar-address-li">
            Sitio Pasinay, Brgy. Pag-Asa  <br />
            Bagac, Bataan, 2107, Philippines  <br />
            Makati City, 1200 Philippines <br />
            <br/>
            For Reservations  <br/>
            Tel: (+632) 8811-5496 <br/>
            &ensp; &ensp; &ensp; (+632) 8892-6497 to 98 <br/>
            &ensp; &ensp; &ensp; (63) 917-527-1806 <br/>
            Fax: (+632) 8811-5235  <br/>
            reservation.mbci@gmail.com <br/>
            <a href="http://www.montemar.com.ph">www.montemar.com.ph</a>
          </li>

          <li className="sidebar-contact-li">PROFESSIONAL STOCK TRANSFER INC</li>
          <li className="sidebar-address-li">
            10th Fl., Telecom Plaza <br/>
            316 Sen. Gil Puyat Av., <br/>
            Makati City, 1200 Philippines <br/>
            Tel: 8687-4053 <br/>
            Fax: 8687-2733 <br/>
            profstocktransferinc@gmail.com <br/>
            <a href="http://www.professionalstocktransfer.com">www.professionalstocktransfer.com</a> 
          </li>
          
        </ul>
      </div>
    );
  }

  

  function renderContent() {
    return (
      <div className={"content-wrap " + deviceType}>
        <h2>PHILCOMSAT</h2>
        <iframe className="montemar-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15446.650444511446!2d121.0185479!3d14.5612763!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4fd12b2324a26377!2sPhilippine+Communications+Satellite+Corporation!5e0!3m2!1sen!2sph!4v1472172187146" frameBorder="0" style={{ border: "0" }} allowFullScreen title="philcomsat-map"></iframe><br />
        <p className="address-note"> *Philcomsat vehicle entrance on H.V. Dela Costa</p><br/>

        <form method="post" action="submit.php" className="contact-form">
          <p className="contact-p">Your Name: <br/>
          <input name="name" className="contact-fields" /></p>
          
          <p className="contact-p">Your Email: <br/>
          <input name="email" className="contact-fields" /></p>
          
          {/* <!-- Important: if you add any fields to this page, you will also need to update the php script --> */}
          <p className="antispam">Leave this empty: <br/>
          <input name="url" /></p>
          
          <p className="contact-p">Your Message: <br/>
          <textarea name="message" rows="10" id="message-area"></textarea></p>
          <p><input type="submit" value="Send" /></p>
        </form>

        <p className="contact-p">Philcomsat email address: <span>inquiry@philcomsat.com.ph</span> </p>  
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Contact info for all the Philcomsat group of companies"
        />
      </Helmet>

      <div className="montemar-container">
        <div className="body-wrap-outer">
          <div className={"body-wrap-inner " + deviceType}>
            {renderSideBar()}
            {renderContent()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;