import React from 'react';

const Page404 = ({ location }) => {
  // Set html <title> tag in <head>
  document.title = "Error 404 - Not Found";

  return (  
    <div>
      <h2 className="error404">Error 404 - No match found for <code>{location.pathname}</code></h2>
    </div>
  );
}

export default Page404;