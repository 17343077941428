import React from 'react';
import { Helmet } from 'react-helmet';

class Press extends React.Component {
  constructor(props) {
    super(props);
    (props.isMobile) ? (this.deviceType = "mobile") : (this.deviceType = "desktop");

    this.state = {
      sortByNewest: true,
    }
  }


  renderSideBar() {
    return (
      <div className={"sidebar-wrap " + this.deviceType}>
        <h1 className={"sidebar-h1-title " + this.deviceType}>FILTER</h1>
        <ul className={"sidebar-ul " + this.deviceType}>
          <li className={this.state.sortByNewest ? "sidebar-active-li" : "sidebar-inactive-li"} onClick={()=> this.setState({sortByNewest: true})} >Newest</li>
          <li className={!this.state.sortByNewest ? "sidebar-active-li" : "sidebar-inactive-li"} onClick={() => this.setState({ sortByNewest: false })} >Oldest</li>
        </ul>
      </div>
    );
  }


  renderContent() {

    let newsItems = [
      (<li className="press-li" key={139}>Manila BULLETIN - July 6, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news138-Global_Business_Power-Bulletin-7-6-21.pdf">Global Business Power to build 115MWp solar plant in Rizal - at the PHILCOMSAT property in Pinugay</a></li>),

      (<li className="press-li" key={138}>BusinessMirror - June 3, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news137-business-mirror-6-3-21.pdf">UNDP returned P284M to DICT from Wi-Fi project, House told by: Cai U. Ordinario</a></li>),

      (<li className="press-li" key={147}>TRIBUNE - June 2, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news136-Daily-Tribune-6-2-21.pdf">Speedcast to return unused WiFi funds</a></li>),        

      (<li className="press-li" key={146}>TRIBUNE - June 1, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news135-notice-to-the-public-6-1-21.pdf">Notice to the Public</a></li>),

      (<li className="press-li" key={145}>Manila BULLETIN - June 1, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news134-manila-bulletin-6-1-21.pdf">UNDP welcomes House resolution to probe 'irregularities' in P1.36-B free wi-fi project</a></li>),

      (<li className="press-li" key={144}>GMA News - June 1, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news133-GMA-News-6-1-21.pdf">DICT claims VSAT Project above board, former USec Rio claims otherwise</a></li>),

      (<li className="press-li" key={143}>ABS-CBN - May 26, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news127-ABSCBN-5-26-21.pdf">"Ex-DICT official bares alleged overprice in free WIFI project"; 
          " 'Para makanood ng Netflix?' Ex-DICT official hits P466-M price tag for free wifi project"</a></li>),
      
      (<li className="press-li" key={142}>ABS-CBN - May 25, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news128-ABSCBN-5-25-21.pdf">"Former and current DICT officials wrangle over canceled free WiFi project"</a></li>),

      (<li className="press-li" key={141}>Inquirer - May 25, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news129-Inquirer-5-25-21.pdf">"Rio bares overprice in DICT's P466M free internet deals"</a></li>),

      (<li className="press-li" key={140}>TRIBUNE - May 25, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1DRerIAvHvxAdArjO5x_i7NZVizrrjbbj/view">Rio fingers all Free Wifi players over delay"; Rappler's "Only 882 of 6,000 sites covered so far under public Wi-Fi project"</a></li>),

      (<li className="press-li" key={139}>TRIBUNE - May 22, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news126-5-22-21-tribune.pdf">PSR #728 "Imee wants 'Free Internet' probed"</a></li>),

      (<li className="press-li" key={138}>TRIBUNE - May 18, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news125-gov-globe-trotters-3.pdf">Commentary "Gov't Globe Trotters" Series of 3</a></li>),

      (<li className="press-li" key={137}>TRIBUNE - May 17, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news124-5-17-21-House-wants-DICT-WiFi-probe.pdf">"House wants DICT WiFi probe"; HR #1751 - House of Representatives</a></li>),

      (<li className="press-li" key={136}>Business Times - series of 2 - May 16, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news123-5-16-oddly-disconnected.pdf">"Oddly disconnected" "A P1.3-B Embarrassment"</a></li>),

      (<li className="press-li" key={135}>TRIBUNE - May 13, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news122-5-13-21-Commentary-Gov-Globe-Trotters.pdf">Commentary "Gov't Globe Trotters (2)"</a></li>),

      (<li className="press-li" key={134}>TRIBUNE - May 11, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news121-5-11-21-Daily-Tribune.pdf">"Palace to UNDP: Return $21M" + Commentary; BusinessWorld's "WiFi contractor asked to reutrn over $21 million"</a></li>),

      (<li className="press-li" key={133}>ABS-CBN - May 10, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news130-ABSCBN-5-10-21.pdf">"Philippines seeks $21-million refund public WiFi contractor"</a></li>),

      (<li className="press-li" key={132}>TRIBUNE - May 8, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news120-5-8-21-Daily-Tribune.pdf">"Panelo: scrap WiFi deal with Speedcast"</a></li>),

      (<li className="press-li" key={131}>TRIBUNE - May 7, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news119-5-7-21-Daily-Tribune-min.pdf">"'DICT can do it on its own' Palace: Return WiFi project fund" + BusinessWorld's "WiFi contractor's reply to demand for return of funds expected soon"</a></li>),

      (<li className="press-li" key={130}>ABS-CBN - May 6, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news131-ABSCBN-5-6-21.pdf">"Palace says free WiFi contractor has until Friday to answer PH demands"</a></li>),

      (<li className="press-li" key={129}>TRIBUNE - May 6, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news118-5-6-21-Daily-Tribune-min.pdf">"CoA perplexed by UNDP role" + Commentary</a></li>),

      (<li className="press-li" key={128}>TRIBUNE - May 5, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news117-free-wifi-needs-no-foreign-firm.pdf">"Free WiFi needs no foreign firm" + Commentary</a></li>),
      
      (<li className="press-li" key={127}>Manila STANDARD, Vito Barcelo, May 4, 2021, page A4<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news116-Standard-05042021.pdf">"PH eyes ending deal with foreign firm over delayed
          Wi-Fi installation"</a></li>),

      (<li className="press-li" key={126}>BusinessWorld, Kyle Aristophere T. Atienza, May 4, 2021, page SI/3<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news115-BWorld-05042021.pdf">"Palace asks DICT to cancel free WiFi deal with Speedcast"</a></li>),

      (<li className="press-li" key={125}>Manila BULLETIN, Genalyn Kabilin, May 4, 2021, page 3<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news114-MnlBulletin-05042021.pdf">"PH to ditch foreign contractor over slow rollout 
        of free internet project"</a></li>),

      (<li className="press-li" key={124}>TRIBUNE - May 4, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news113-palace-wants-speedcast-dropped.pdf">"Palace wants Speedcast dropped"; "DICT to UNDP: Return our Money"; "Nationwide free WiFi project: Better without Speedcast?" + Commentary</a></li>),

      (<li className="press-li" key={123}>ABS-CBN - May 3, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news132-ABSCBN-5-3-21.pdf">"Still no free public WiFi; Palace says gov't unhappy with project contractor"</a></li>),

      (<li className="press-li" key={122}>TRIBUNE Front Page, May 3, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news112-speedcast-owes.pdf">"Speedcast owes gov't P5M more"; 
          "Updated Free WiFi Timeline" + Commentary
        </a></li>),

      (<li className="press-li" key={121}>TRIBUNE Front Page, May 2, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news111-congress-to-probe-free-wifi.pdf">"Congress to probe free WiFi fiasco"; 
        "UNDP: No Customs report, no comment" + Commentary
        </a></li>),      

      (<li className="press-li" key={120}>TRIBUNE Front Page, May 1, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news110-DTribune-05012021.pdf">"Rio to UNDP: Kick out Speedcast" + Commentary</a></li>),

      (<li className="press-li" key={119}>TRIBUNE Front Page, April 30, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news109-DTribune-0430221.pdf">"Philcomsat blames UNDP"; "Problematic 'Free WiFi'"; + Commentary</a></li>),

      (<li className="press-li" key={118}>TRIBUNE Front Page, April 29, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news108-DTribune-04292021.pdf">"DICT orders probe of free WiFi scandal" + Commentary</a></li>),

      (<li className="press-li" key={117}>TRIBUNE - April 28, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news107-free-wifi-in-peril.pdf">"P1.B FREE WIFI PROJECT IN PERIL"</a></li>),
        
      (<li className="press-li" key={116}>PhilSTAR Victor Agustin 'Money-Go-Round', April 23, 2021<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news106-philstar-april-23-2021.pdf">"Customs catches UN contractor smuggling satellite equipment for WiFi project"</a></li>),

      (<li className="press-li" key={115}>PhilSTAR Victor Agustin 'Money-Go-Round', Aug 11, 2020<br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news105-MoneyGoRound-08232020.pdf">"UN-Aussie venture botches Duterte's free WiFi project"</a></li>),


      (<li className="press-li" key={114}>Philippine Daily Inquirer - April 6, 2020<br/>
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/2020-daily-inquirer-simon-piggott-1.pdf">NOTICE TO THE PUBLIC re SIMON J. PIGGOTT</a></li>),

      (<li className="press-li" key={113}>Press Release - November 6, 2019<br/>
      <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news104.pdf">Nominations to the PHC Board</a></li>),

      (<li className="press-li" key={112}>Press Release - October 10, 2019<br/>
        <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/UNDP_Press_Release.pdf">DICT & UNDP Award Contract to Connect 3,000 Remote Locations with Free Internet</a></li>),

      (<li className="press-li" key={111}>Philippine Star - May 21, 2019<br />
      <a target="_blank" rel="noopener noreferrer" href="https://www.philcomsat.com.ph/press_files/news103.pdf">"Smart, Globe inherit RSA's lease headache" by: Victor C. Agustin</a></li>),

      (<li className="press-li" key={110}>Philippine Star - March 15, 2019<br />
      <a target="_blank" rel="noopener noreferrer" href="https://www.philstar.com/business/2019/03/15/1901718/duterte-signs-law-extending-philcomsat-franchise">"Duterte signs law extending Philcomsat franchise" by: Ian Nicolas Cigaral</a></li>),

      (<li className="press-li" key={109}>Business Mirror, News - March 10, 2016<br/>
      <a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/press/news102.pdf">"SC finally lifts sequestration of POTC and unit Philcomsat"</a></li>),

      (<li className="press-li" key={108}>Philippine Star, Opinion - March 10, 2016<br/>
      <a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/press/news101.pdf">"30-year sequestration of Philcomsat lifted"  by: Federico D. Pascual, Jr. </a></li>),

      (<li className="press-li" key={107}>Philippine Star, Opinion - November 26, 2015<br/>
      <a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/press/news100.pdf">"What's the going rate for high-value TRO's?"  by: Federico D. Pascual, Jr. </a></li>),

      (<li className="press-li" key={106}>Malaya - Business Insight - October 27, 2015<br/>
      <a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/press/news99.pdf">"Administrative Liability"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={105}>Malaya - Business Insight - June 24, 2015<br/>
      <a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/press/news98.pdf">"Bautista not fit to head Comelec"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={104}>Business Mirror - June 16, 2015<br/>
      <a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/press/news97.pdf">"Business group opposes Bautista's nomination to Comelec"  by: The Nation </a></li>),

      (<li className="press-li" key={103}>The Manila Times - June 15, 2015<br/>
      <a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/press/news96.pdf">"Why ex-PCGG chief Bautista should not be Comelec chair"  by: Emeterio Sd. Perez </a></li>),

      (<li className="press-li" key={102}>The Manila Times - June 12, 2015<br/>
      <a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/press/news95.pdf">"List PHC shares not traded because of PCGG"  by: Emeterio Sd. Perez </a></li>),

      (<li className="press-li" key={101}>Malaya - Business Insight - June 2, 2015<br/>
      <a target="_blank" rel="noopener noreferrer" href="http://www.phc.com.ph/press/news94.pdf">"PCGG's idea of undue injury"  by: Amado P. Macasaet </a></li>),
      
      (<li className="press-li" key={100}>Malaya - Business Insight - May 26, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news93.pdf">"Sequestration, an excuse to loot"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={99}>Malaya - Business Insight - May 21, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news92.pdf">"Andy Bautista has two jobs"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={98}>Malaya - Business Insight - May 18, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news91.pdf">"A victim, not a crony"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={97}>Malaya - Business Insight - April 29, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news90.pdf">"PCGG defiance"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={96}>Malaya - Business Insight - April 23, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news89.pdf">"PCGG as plunderer"  by: Amado P. Macasaet </a></li>),
      
      (<li className="press-li" key={95}>Malaya Business Insight - April 6, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/040615MalayaArticle.pdf">"The State as Looters of Private Funds" by: Amado P. Macasaet</a></li>),

      (<li className="press-li" key={94}>PHC - April 1, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/april1_cartoon_without_pcgg.pdf">"SEC: a watchdog"</a></li>),
      
      (<li className="press-li" key={93}>PHC - April 1, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/april1_cartoon_with_pcgg.pdf">"Inter-agency courtesy"</a></li>),

      (<li className="press-li" key={92}>Malaya - Business Insight, March 19, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news86.pdf">"Go on and looting; ignore the law"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={91}>Malaya - Business Insight, March 18, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news85.pdf">"Usurpation of powers"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={90}>Malaya - Business Insight, March 13, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news84.pdf">"Rape of rights and assets"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={89}>Malaya - Business Insight, March 11, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news83.pdf">"PCGG abets own mistake"  by: Amado P. Macasaet </a></li>),
  
      (<li className="press-li" key={88}>Malaya - Business Insight, March 10, 2015<br/>
      <a target="new" href="http://www.phc.com.ph/press/news82.pdf">"PCGG defies all including SC"  by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={87}>The Philippine Star - OPINION, November 06, 2014<br/>
      <a target="new" href="http://www.phc.com.ph/press/news81.pdf">"Postscript"  by: Federico D. Pascual Jr.</a></li>),

      (<li className="press-li" key={86}>Malaya - Business  Insight, October 13, 2014<br/>
      <a target="new" href="http://www.phc.com.ph/press/news80.pdf">"Thirst for authority that does not exist" by: Amado P. Macasaet </a></li>),

      (<li className="press-li" key={85}>Malaya - Business  Insight, October 10, 2014<br/>
      <a target="new" href="http://www.phc.com.ph/press/news79.pdf">"SEC sings sour note on Philcomsat" by: Amado P. Macasaet</a></li>),

      (<li className="press-li" key={84}>Malaya - Business  Insigh, October 9, 2014<br/>
      <a target="new" href="http://www.phc.com.ph/press/news78.pdf">"It's the liars who figure" by: Amado P. Macasaet</a></li>),

      (<li className="press-li" key={83}>Philippine Star - Business, October 6, 2014<br/>
      <a target="new" href="http://www.phc.com.ph/press/news77.pdf">"Gov't Commissioners hold 'hostage' defiant Enrile-linked firm" by: Victor C. Agustin</a></li>),

      (<li className="press-li" key={82}>The online news portal of TV5, October 6, 2014<br/>
      <a target="new" href="http://www.phc.com.ph/press/news77a.pdf">"COCKTALES / Gov't Commissioners hold 'hostage' defiant Enrile-linked firm" by: Victor C. Agustin</a></li>),

      (<li className="press-li" key={81}>The Philippine STAR, May 19, 2014<br/>
      <a target="new" href="http://www.phc.com.ph/press/VAgustin.pdf">Bankers lose, lawyers win</a></li>),
      
      (<li className="press-li" key={80}>Malaya Business Insight, April 28, 2014<br/>
      <a target="new" href="http://www.phc.com.ph/press/Malaya.pdf">BPI's delaying tactics</a></li>),
      
      (<li className="press-li" key={79}>The Philippine STAR, October 3, 2013<br/>
      <a target="new" href="http://www.phc.com.ph/press/news76.pdf">It's still a bribe whether before or after the deed</a></li>),
      
      (<li className="press-li" key={78}>Malaya Business Insight, August 14, 2013<br/>
      <a target="new" href="http://www.phc.com.ph/press/news75.pdf">SC orders return of P700-M 'loot'</a></li>),
      
      (<li className="press-li" key={77}>The Philippine STAR, August 4, 2013<br/>
      <a target="new" href="http://www.phc.com.ph/press/news74.pdf">SC finally settles Philcomsat feud</a></li>),
      
      (<li className="press-li" key={76}>The Philippine STAR, August 28, 2012 <br/>
      <a target="new" href="http://www.phc.com.ph/press/news73.pdf">Philcomsat says no to PCGG returning</a></li>),
      
      (<li className="press-li" key={75}>Malaya Business Insight, July 24, 2012<br/>
      <a target="new" href="http://www.phc.com.ph/press/news71.pdf">PCGG chair is under probe</a></li>),
      
      (<li className="press-li" key={74}>The Manila Times, June 29, 2012 <br/>
      <a target="new" href="http://www.phc.com.ph/press/news70.pdf">Senate report on PCGG 'mismanagement' upheld</a></li>),
      
      (<li className="press-li" key={73}>The Philippine STAR, May 13, 2012<br/>
      <a target="new" href="http://www.phc.com.ph/press/news69.pdf">Fair warnings to VIPs with arrest warrants</a></li>),
      
      (<li className="press-li" key={72}>Malaya Business Insight, May 10, 2012<br/>
      <a target="new" href="http://www.phc.com.ph/press/news68.pdf">Incidental Intelligence</a></li>),
      
      (<li className="press-li" key={71}>Manila Standard Today, May 09, 2012<br/>
      <a target="new" href="http://www.phc.com.ph/press/news67.pdf">Enrique Locsin arrested</a></li>),
      
      (<li className="press-li" key={70}>Malaya Business Insight, May 07, 2012<br/>
      <a target="new" href="http://www.phc.com.ph/press/news66.pdf">Plunder by PCGG</a></li>),
      
      (<li className="press-li" key={69}>Malaya Business Insight, April 20, 2012<br/>
      <a target="new" href="http://www.phc.com.ph/press/news65.pdf">Illegal annotation</a></li>),
      
      (<li className="press-li" key={68}>Malaya Business Insight, April 17, 2012<br/>
      <a target="new" href="http://www.phc.com.ph/press/news64.pdf">PCGG's arbitrary acts.</a></li>),
      
      (<li className="press-li" key={67}>Philippine Daily Inquirer, December 09, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news63.pdf">2 former Philcomsat execs charged with graft</a></li>),
      
      (<li className="press-li" key={66}>Philippine Daily Inquirer, November 01, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news62.pdf">A matter of 'when' and not 'if'</a></li>),
      
      (<li className="press-li" key={65}>Philippine Daily Inquirer, October 04, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news61.pdf">A lot of explaining to do</a></li>),
      
      (<li className="press-li" key={64}>Malaya, August 30, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news60.pdf">PCGG's mumbo jumbo</a></li>),
      
      (<li className="press-li" key={63}>Malaya, August 29, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news59.pdf">Recovered wealth lifts sequestration</a></li>),
      
      (<li className="press-li" key={62}>Malaya, August18, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news56.pdf">Andy Bautista and core values</a></li>),
      
      (<li className="press-li" key={61}>Malaya, August 17, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news55.pdf">Incidental Intelligence</a></li>),
      
      (<li className="press-li" key={60}>The Philippine STAR , August 14, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news58.pdf">Postscript - Abcede case</a></li>),
      
      (<li className="press-li" key={59}>Malaya, August 11, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news57.pdf">PCGG is also unconstitutional</a></li>),
      
      (<li className="press-li" key={58}>Malaya, July 28, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news51.pdf">PCGG bled Philcomsat dry</a></li>),
      
      (<li className="press-li" key={57}>Malaya, July 22, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news53.pdf">The PCGG must go</a></li>),
      
      (<li className="press-li" key={56}>Manila Standard Today, July 22, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news54.pdf">PCGG empire builder bypasses De Lima</a></li>),
      
      (<li className="press-li" key={55}>The Philippine STAR, July 21, 2011<br/>
      <a target="new" href="http://www.phc.com.ph/press/news52.pdf">PCGG eyeing return to looted companies</a></li>),
      
      (<li className="press-li" key={54}>Malaya, May 31, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news50.pdf">PHC writes off P600M 'toxic,' missing assets</a></li>),
      
      (<li className="press-li" key={53}>The Dailly Tribune, May 31, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news49.pdf">Philcomsat branches out into property dev't</a></li>),
      
      (<li className="press-li" key={52}>Manila Times, May 31, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news48.pdf">Philcomsat Holdings to raise funds via share sale</a></li>),
      
      (<li className="press-li" key={51}>Business Mirror, May 30, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news47.pdf">Philcomsat set to venture into property dev't</a></li>),
      
      (<li className="press-li" key={49}>Philippine Daily Inquirer, May 29, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news46.pdf">Philcomsat eyes revival of businesses</a></li>),
      
      (<li className="press-li" key={48}>Manila Standard Today, May 28, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news44.pdf">Grilling Gigi</a></li>),
      
      (<li className="press-li" key={47}>Malaya, May 28, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news43.pdf">Montinola: 'We simply followed court orders'</a></li>),
      
      (<li className="press-li" key={46}>Manila Standard Today, May 21, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news42.pdf">Montinola can't shake off tough Makati magistrate</a></li>),
      
      (<li className="press-li" key={45}>Philippine Daily Inquirer, March 16, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news41.pdf">Philcomsat Holdings' future</a></li>),
      
      (<li className="press-li" key={44}>Manila Standard Today, March 15, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news40.pdf">Montinola dodges Makati magistrate</a></li>),
      
      (<li className="press-li" key={43}>Business World, March 11, 2010<br/>
      <a target="new" href="http://www.phc.com.ph/press/news39.pdf">BPI executive told to explain huge Philcomsat withdrawals</a></li>),
      
      (<li className="press-li" key={42}>The Philippine STAR, November 19, 2009<br/>
      <a target="new" href="http://www.phc.com.ph/press/news38.pdf">Courts must tell banks, corporate raiders to pay</a></li>),
      
      (<li className="press-li" key={41}>Manila Standard Today, November 20, 2009<br/>
      <a target="new" href="http://www.phc.com.ph/press/news37.pdf">Enriles go after Montinola</a></li>),
      
      (<li className="press-li" key={40}>The Philippine STAR, January 4, 2009<br/>
      <a target="new" href="http://www.phc.com.ph/press/news36.pdf">Montemar club's stink jolts owners, members</a></li>),
      
      (<li className="press-li" key={39}>The Philippine STAR, February 3, 2008<br/>
      <a target="new" href="https://www.philstar.com/opinion/2008/02/03/42437/more-named-jpersquos-lsquoali-babarsquo-plunder-list">Sen JPE's Privilege Speech "Alibaba + 40 Thieves"</a></li>),
      // https://www.senate.gov.ph/lisdata/678312259!.pdf#page=5  also works

      (<li className="press-li" key={38}>Manila Standard Today, October 6, 2008<br/>
      <a target="new" href="http://www.phc.com.ph/press/news35.pdf">Locsin loses legitimacy battle</a></li>),
      
      (<li className="press-li" key={37}>MALAYA, October 6, 2008<br/>
      <a target="new" href="http://www.phc.com.ph/press/news34.pdf">Locsin group suit vs. telco junked</a></li>),
      
      (<li className="press-li" key={36}>The Philippine STAR, July 6, 2008<br/>
      <a target="new" href="http://www.phc.com.ph/press/news33.pdf">PCGG finally drops Philcomsat: It's broke</a></li>),
      
      (<li className="press-li" key={35}>Business World, July 3, 2008<br/>
      <a target="new" href="http://www.phc.com.ph/press/news32.pdf">Philcomsat shares given to PMO</a></li>),
      
      (<li className="press-li" key={34}>The Philippine STAR, June 14, 2008<br/>
      <a target="new" href="http://www.phc.com.ph/press/news31.pdf">PCGG upholds election of new gov't nominees in Philcomsat</a></li>),
      
      (<li className="press-li" key={33}>The Philippine STAR, May 18, 2008<br/>
      <a target="new" href="http://www.phc.com.ph/press/news30.pdf">'Cash Cow' gang runs call center to the ground</a></li>),
      
      (<li className="press-li" key={32}>The Philippine STAR, May 11, 2008<br/>
      <a target="new" href="http://www.phc.com.ph/press/news29.pdf">PCGG fund scandals keep haunting Sabio</a></li>),
      
      (<li className="press-li" key={31}>THE DAILY TRIBUNE, April 27, 2008<br/>
      <a target="new" href="http://www.phc.com.ph/press/news28.pdf">Who is Veronica Nepomuceno?</a></li>),
      
      (<li className="press-li" key={30}>Manila Standard Today, January 24, 2008<br/>
      <a target="new" href="http://www.phc.com.ph/press/news27.pdf">Sandigan asks: What P2M PR fund?</a></li>),
      
      (<li className="press-li" key={29}>Philippine DAILY INQUIRER, December 17, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news26.pdf">Makati court freezes funds of PHC</a></li>),
      
      (<li className="press-li" key={28}>November 19, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news76.pdf">Statement by the Poblador Family</a></li>),
      
      (<li className="press-li" key={27}>November 13, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news76.pdf">The desire letter of the President for the gov't directors to Philcomsat</a></li>),
      
      (<li className="press-li" key={26}>November 13, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news76.pdf">The proxy held by Usec. Enrique Perez</a></li>),
      
      (<li className="press-li" key={25}>November 18, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news76.pdf">The letter from Usec. Enrique Perez to PCGG Chairman Camilo Sabio</a></li>),
      
      (<li className="press-li" key={24}>The Philippine STAR, November 20, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news25.pdf">Philcomsat owners agree on unified board of directors</a></li>),
      
      (<li className="press-li" key={23}>Philippine DAILY INQUIRER, November 20, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news24.pdf">Malacanang replaces Philcomsat nominees</a></li>),
      
      (<li className="press-li" key={22}>The DAILY TRIBUNE, November 20, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news23.pdf">Gov't boots out PCGG nominees in Philcomsat</a></li>),
      
      (<li className="press-li" key={21}>The Philippine STAR, November 16, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news22.pdf">Philcomsat shareholders seek new gov't nominees</a></li>),
      
      (<li className="press-li" key={20}>MANILA BULLETIN, November 16, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news21.pdf">Responsible Philcomsat nominees sought</a></li>),
      
      (<li className="press-li" key={19}>MALAYA, August 3, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news20.pdf">Dismissal of CA justice sought</a></li>),
      
      (<li className="press-li" key={18}>MALAYA, July 27, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news19.pdf">More telecom execs summoned on P2M 'bribe'</a></li>),
      
      (<li className="press-li" key={17}>The Philippine STAR, July 17, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news14.pdf">Questionable deal? Blame Alzheimer's</a></li>),
      
      (<li className="press-li" key={16}>MALAYA, July 17, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news15.pdf">Philcomsat Holdings prexy is mentally unfit; name used in 'looting' company</a></li>),
      
      (<li className="press-li" key={15}>The Manila Times, July 6, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news18.pdf">Controversial PCGG official out of Philcomsat</a></li>),
      
      (<li className="press-li" key={14}>TEMPO, July 6, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news17.pdf">Sabio replaces Abcede as Philcomsat head</a></li>),
      
      (<li className="press-li" key={13}>Manila Standard Today, July 6, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news13.pdf">PCGG man in ill-gotten car case is dismissed</a></li>),
      
      (<li className="press-li" key={12}>The Philippine STAR, July 1, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news12.pdf">"Postscript"  by: Federico D. Pascual Jr.</a></li>),
      
      (<li className="press-li" key={11}>The Daily Tribune, July 1, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news11.pdf">Senate Committee Report 312</a></li>),
      
      (<li className="press-li" key={10}>The Philippine STAR, June 28, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news10.pdf">GMA extends revamp to appointees of sequestered coporations</a></li>),
      
      (<li className="press-li" key={9}>Malaya, June 25, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news9.pdf">Senate Commitee Report No. 312 Adopted by the Plenary June 7, 2007</a></li>),
      
      (<li className="press-li" key={8}>The Philippine STAR, June 19, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news8.pdf">President can't ignore looting of PHILCOMSAT</a></li>),
      
      (<li className="press-li" key={7}>Manila Bulletin, June 17, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news7.pdf">Senate panel seeks overhaul of PCGG</a></li>),
      
      (<li className="press-li" key={6}>The Daily Tribune, June 15, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news6.pdf">PCGG shows deboning skill at Philcomsat</a></li>),
      
      (<li className="press-li" key={5}>The Daily Tribune, June 14, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news5.pdf">PCGG asked to account for 20 years of plunder</a></li>),
      
      (<li className="press-li" key={4}>The Daily Tribune, June 13, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news4.pdf">Philcomsat squeezed dry by nominees - Senate report</a></li>),
      
      (<li className="press-li" key={3}>INQUIRER, June 8, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news3.pdf">Prosecution of PCGG men in PHILCOMSAT board sought</a></li>),
      
      (<li className="press-li" key={2}>MALAYA, June 8, 2007<br/>
      <a target="new" href="http://www.phc.com.ph/press/news2.pdf">Senate: Sack gov't men in telecom firms</a></li>),
      
      // (<li className="press-li" key={1}>April 27, 2007<br/>
      // <a target="new" href="http://philcomsat.com.ph/recent_devt.html">The TRO to stop the real owners from taking over PHILCOMSAT HOLDINGS is TOO late and was obtained by FORUM SHOPPING</a></li>)
      // ^404 Not found, so I removed
    ];

    if (!this.state.sortByNewest) {
      newsItems = newsItems.reverse();
    }
    
    return (
      <div className={"content-wrap " + this.deviceType}>
        <h2>PRESS</h2>
        {newsItems}
        <p id="press-top-page" onClick={() => window.scrollTo(0,0)}>TOP OF PAGE</p>
      </div>
    );
  }


  render() {
    return (
      <>
        <Helmet>
          <title>Press</title>
          <meta
            name="description"
            content="Press related to the Philcomsat group of companies"
          />
        </Helmet>

        <div className="press-container">
          <div className="body-wrap-outer">
            <div className={"body-wrap-inner " + this.deviceType}>
              {this.renderSideBar()}
              {this.renderContent()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Press;