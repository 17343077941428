import React from 'react';
import {
  BrowserRouter as Router,
  // HashRouter,                                 // lets us have front end UI in sync with URL (works for older browsers, but not the best for google seo indexing)
  Switch,
  Route,
  // Link
} from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import NavBar from './navbar';
import About from './about';
import Services from './services';
import Press from './press';
import Contact from './contact';
import Home from './home';
import SideDrawer from './SideDrawer/SideDrawer';
import Backdrop from './backdrop';
import Montemar from './montemar';
import PHC from './phc';
import Energy from './energy';
import Footer from './footer';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import Page404 from './page404.js';


// Google Analytics Tracking ID
const TRACKING_ID = "UA-81701265-1";
const history = createBrowserHistory();


// Used as values for state in About pages
const BACKGROUND = 'Background';
const COMPANIES = 'Philcomsat Group of Companies';
const MANAGEMENT_POTC = 'Philippine Overseas Telecommunications Corp. (POTC)';
const MANAGEMENT_PHILCOMSAT = 'Philippine Communications Satellite Corp. (PHILCOMSAT)';
const MANAGEMENT_PHC = 'Philcomsat Holdings Corp. (PHC)';
const MANAGEMENT_MBCI = 'Montemar Beach Club Inc. (MBCI)';
const PERMITS = 'Regulatory Permits';
// Used as values for state in Services pages
const DEFAULT = 'Making Distances Irrelevant';
const CIRCUITS = 'International & Domestic Lease Circuits';
const AERONAUTICAL = 'Aeronautical Communications';
const MARITIME = 'Maritime Communications';
const DISTANCE_LEARNING = 'Distance Learning';
const BROADCAST_TV = 'Broadcast TV Services';
const DISASTER_MANAGEMENT = 'Disaster Management';
const INTERNET_VSAT = 'Internet Broadband via VSAT';


// Classical Component
class Root extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sideDrawerOpen: false,
    };

    // Initialise the ReactGA with Google Analytics tracking code so GA knows which collector to push the data to
    ReactGA.initialize(TRACKING_ID);

    history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update users current page
      ReactGA.pageview(location.pathname);      // Record a pageview for the given page
    });
  }


  handleClickDrawerToggleButton = () => {
    this.setState((prevState)=> {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  }


  handleClickBackdrop = () => {
    this.setState({
      sideDrawerOpen: false    // always closes sidedrawer when u click
    });
  }


  render() {
    const { sideDrawerOpen } = this.state;
    let sideDrawer;
    let backdrop;
    let mobileView;
    
    isMobile ? (mobileView = true) : (mobileView = false);

    if (sideDrawerOpen) {
      sideDrawer = <SideDrawer show={sideDrawerOpen} drawerClickHandler={this.handleClickDrawerToggleButton}/>;
      backdrop = <Backdrop click={this.handleClickBackdrop}/>;
    }

    return (
      // <HashRouter>
      <Router>
        <NavBar 
          drawerClickHandler={this.handleClickDrawerToggleButton}
          isMobile={mobileView}
        />
        {sideDrawer}
        {backdrop}
      
        {/* <Switch> looks through its children <Route>s and renders
        the first component that matches the current URL */}
        <Switch>
          <Route exact path="/about" render={(props) => <About {...props} isMobile={mobileView} aboutProps={BACKGROUND}/>} />
          <Route exact path="/about/ownership" render={(props) => <About {...props} isMobile={mobileView} aboutProps={COMPANIES}/>} />
          <Route exact path="/about/permits" render={(props) => <About {...props} isMobile={mobileView} aboutProps={PERMITS}/>} />
          <Route exact path="/about/management-potc" render={(props) => <About {...props} isMobile={mobileView} aboutProps={MANAGEMENT_POTC} showManagement={true}/>} />
          <Route exact path="/about/management-philcomsat" render={(props) => <About {...props} isMobile={mobileView} aboutProps={MANAGEMENT_PHILCOMSAT} showManagement={true}/>} />
          <Route exact path="/about/management-phc" render={(props) => <About {...props} isMobile={mobileView} aboutProps={MANAGEMENT_PHC} showManagement={true}/>}/>
          <Route exact path="/about/management-mbci" render={(props) => <About {...props} isMobile={mobileView} aboutProps={MANAGEMENT_MBCI} showManagement={true}/>} />
          <Route exact path="/services" render={(props) => <Services {...props} isMobile={mobileView} aboutProps={DEFAULT}/>} />
          <Route exact path="/services/lease-circuits" render={(props) => <Services {...props} isMobile={mobileView} aboutProps={CIRCUITS}/>} />
          <Route exact path="/services/aeronautical" render={(props) => <Services {...props} isMobile={mobileView} aboutProps={AERONAUTICAL}/>} />
          <Route exact path="/services/maritime" render={(props) => <Services {...props} isMobile={mobileView} aboutProps={MARITIME}/>} />
          <Route exact path="/services/distance-learning" render={(props) => <Services {...props} isMobile={mobileView} aboutProps={DISTANCE_LEARNING}/>} />
          <Route exact path="/services/broadcast-tv" render={(props) => <Services {...props} isMobile={mobileView} aboutProps={BROADCAST_TV}/>} />
          <Route exact path="/services/disaster-management" render={(props) => <Services {...props} isMobile={mobileView} aboutProps={DISASTER_MANAGEMENT}/>} />
          <Route exact path="/services/internet-vsat" render={(props) => <Services {...props} isMobile={mobileView} aboutProps={INTERNET_VSAT}/>} />
          <Route exact path="/phc" render={(props) => <PHC {...props} isMobile={mobileView} />} />
          <Route exact path="/montemar" render={(props) => <Montemar {...props} isMobile={mobileView} />} />
          <Route exact path="/press" render={(props) => <Press {...props} isMobile={mobileView} />} />
          <Route exact path="/contact" render={(props) => <Contact {...props} isMobile={mobileView} />}/>
          <Route exact path="/energy" render={(props) => <Energy {...props} isMobile={mobileView} />}/>
          <Route exact path="/" render={(props) => <Home {...props} isMobile={mobileView}/>} />
          <Route component={Page404} />
          {/* <Route path="/" component={Home} /> */}
        </Switch>

        <Footer
          isMobile={mobileView}
        />
      {/* </HashRouter> */}
      </Router>
    );
  }
};

export default Root;