import React from 'react';
import { Link } from 'react-router-dom';

// Child Component of Root Component
const SideDrawer = props => {
  let drawerClass = 'side-drawer';

  if (props.show) drawerClass = 'side-drawer open';

  return (
    <nav className={drawerClass}>
      <button className="close-drawer" onClick={props.drawerClickHandler}>X</button>
      <ul>
        <li><Link onClick={props.drawerClickHandler} to="/">HOME</Link></li>
        <li><Link onClick={props.drawerClickHandler} to="/about">ABOUT</Link></li>
        <li><Link onClick={props.drawerClickHandler} to="/services">SERVICES</Link></li>
        <li><Link onClick={props.drawerClickHandler} to="/phc">PHILCOMSAT HOLDINGS CORP</Link></li>
        <li><Link onClick={props.drawerClickHandler} to="/montemar">MONTEMAR</Link></li>
        <li><Link onClick={props.drawerClickHandler} to="/press">PRESS</Link></li>
        <li><Link onClick={props.drawerClickHandler} to="/contact">CONTACT</Link></li>
      </ul>
    </nav>
  );
};

export default SideDrawer;