import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// used as values for state
const DEFAULT = 'Making Distances Irrelevant';
const CIRCUITS = 'International & Domestic Lease Circuits';
const AERONAUTICAL = 'Aeronautical Communications';
const MARITIME = 'Maritime Communications';
const DISTANCE_LEARNING = 'Distance Learning';
const BROADCAST_TV = 'Broadcast TV Services';
const DISASTER_MANAGEMENT = 'Disaster Management';
const INTERNET_VSAT = 'Internet Broadband via VSAT';


class Services extends React.Component {
  constructor(props) {
    super(props);
    (props.isMobile) ? (this.deviceType = "mobile") : (this.deviceType = "desktop");

    // https://medium.com/@bopaiahmd.mca/how-to-pass-props-using-link-and-navlink-in-react-router-v4-75dc1d9507b4
    // ex. 
    // props.location.aboutProps == "BACKGROUND"

    let h2TitleValue = props.aboutProps || DEFAULT;

    this.state = {
      h2Title: h2TitleValue
    };
  }


  componentDidUpdate() {
    let newState = this.props.aboutProps;

    if (newState !== this.state.h2Title) {
      this.setState({
        h2Title: newState
      });
    }
  }


  renderSideBar() {
    
    return (
      <div className={"sidebar-wrap " + this.deviceType}>
        <h1 className={"sidebar-h1-title " + this.deviceType}>SATELLITE SERVICES</h1>
        <ul className={"sidebar-ul " + this.deviceType}>
          <li
            className={this.state.h2Title === INTERNET_VSAT ? "sidebar-active-li" : "sidebar-inactive-li"}
            onClick={() => {
              this.props.history.push('/services/internet-vsat');
              // return this.setState({ h2Title: INTERNET_VSAT })
            }
            }
          >Internet Broadband via VSAT</li>
          
          <li
            className={this.state.h2Title === CIRCUITS ? "sidebar-active-li" : "sidebar-inactive-li"}
            onClick={() => {
              this.props.history.push('/services/lease-circuits')
              // return this.setState({ h2Title: CIRCUITS })
            }
            }
          >International & Domestic Lease Circuits</li>

          <li
            className={this.state.h2Title === AERONAUTICAL ? "sidebar-active-li" : "sidebar-inactive-li"}
            onClick={() => {
              this.props.history.push('/services/aeronautical');
              // return this.setState({ h2Title: AERONAUTICAL })   
            }
            }
          >Aeronautical Communications</li>

          <li
            className={this.state.h2Title === MARITIME ? "sidebar-active-li" : "sidebar-inactive-li"}
            onClick={() => {
              this.props.history.push('/services/maritime');
              // return this.setState({ h2Title: MARITIME })
            }
            }
          >Maritime Communications</li>

          <li
            className={this.state.h2Title === DISTANCE_LEARNING ? "sidebar-active-li" : "sidebar-inactive-li"}
            onClick={() => {
              this.props.history.push('/services/distance-learning');
              // return this.setState({ h2Title: DISTANCE_LEARNING })
            }
            }
          >Distance Learning</li>

          <li
            className={this.state.h2Title === BROADCAST_TV ? "sidebar-active-li" : "sidebar-inactive-li"}
            onClick={() => {
              this.props.history.push('/services/broadcast-tv');
              // return this.setState({ h2Title: BROADCAST_TV })
            }
            }
          >Broadcast TV Service</li>

          <li
            className={this.state.h2Title === DISASTER_MANAGEMENT ? "sidebar-active-li" : "sidebar-inactive-li"}
            onClick={() => {
              this.props.history.push('/services/disaster-management');
              // return this.setState({ h2Title: DISASTER_MANAGEMENT })
            }
            }
          >Disaster Management Solutions</li>
        </ul>
      </div>
    );
  }


  renderContent() {
    let content;

    // Depending on state, render appropriate side content
    switch (this.state.h2Title) {
      case (DEFAULT):
        content = this.renderDefault();
        break;
      case (CIRCUITS):
        content = this.renderCircuits();
        break;
      case (AERONAUTICAL):
        content = this.renderAeronautical();
        break;
      case (MARITIME):
        content = this.renderMaritime();
        break;
      case (DISTANCE_LEARNING):
        content = this.renderDistanceLearning();
        break;
      case (BROADCAST_TV):
        content = this.renderBroadcastTV();
        break;
      case (DISASTER_MANAGEMENT):
        content = this.renderDisasterManagement();
        break;
      case (INTERNET_VSAT):
        content = this.renderInternetVSAT();
        break;
      default:
        content = DEFAULT;
    }

    return (
      <div className={"content-wrap " + this.deviceType}>
        <h2>{this.state.h2Title}</h2>
        {content}
      </div>
    );
  }


  renderDefault() {
    return (
      <>
        <Helmet>
          <title>Services - Philcomsat</title>
          <meta
            name="description"
            content="We have a range of telecommunications satellite services, particularly VSAT Broadband Internet technology."
      />
        </Helmet>

        <div>
          <p className="content-p">Philcomsat has developed a range of telecommunications products and services, focusing on satellite operations, particularly VSAT technology.</p>
          <br />
          <p className="content-p">PHILCOMSAT’s IP communication services encompass a broad range of applications from the traditional and robust C-band Very Small Aperture Terminals (VSAT) to the new Ka-, and Ku-band High Throughput Satellites (HTS), which can provide more than 1Gigabit per second capacity. These solutions are also available as redundancy and backhaul to existing GSM/3G/4G/LTE Networks, as well as the transport of internet broadband and Virtual Private Network (VPN) requirements for enterprise, government and non-government organizations operating inside and outside the Philippines.</p>
          <br />
          <p className="content-p">In addition to its telecommunications mega-franchise which expires in 2044, PHILCOMSAT also maintains licenses in VSAT, value-added services (VAS), International Satellite Carrier (ISC), Voice Over Internet Protocol (VOIP), and INMARSAT.</p>
          <br />
        </div>
      </>
    );
  }


  renderCircuits() {
    return (
      <>
        <Helmet>
          <title>International & Domestic Lease Circuits</title>
          <meta
            name="description"
            content="International & Domestic Lease Circuits"
          />
        </Helmet>

        <p className="content-p">International Private Leased Circuit (IPLC) is an end-to-end dedicated telecommunications transmission for customers’ exclusive use. It provides companies with an efficient and cost-effective telecommunications system that support voice, fax, data, messaging, image, video or a combination of them with point-to-point connections among headquarters and overseas locations.</p>
        <br/>
        <p className="content-p">If you need more information or would like a quote, please <Link to='/contact'>contact us</Link></p>
        <img className="services-img" src={process.env.PUBLIC_URL + '/images/services-IPLC-VSAT.jpg'} alt="Lease Circuit Diagram" />
      </>
    );
  }


  renderAeronautical() {
    return (
      <>
        <Helmet>
          <title>Aeronautical Services</title>
          <meta
            name="description"
            content="Satellte solutions for planes"
          />
        </Helmet>

        <p className="content-p">Philcomsat offers air-to-air, and air-to-ground communications for the air transport service. Capabilities include internet, voice, data, and tracking for cabin and cockpit needs.</p>
        <br />
        <p className="content-p">If you need more information or would like a quote, please <Link to='/contact'>contact us</Link></p>
        <img className="services-img" src={process.env.PUBLIC_URL + '/images/services-aeronautical.jpg'} alt="C130 Plane" />
      </>
    );
  }


  renderMaritime() {
    return (
      <>
        <Helmet>
          <title>Maritime Services</title>
          <meta
            name="description"
            content="Satellte solutions for all maritime vessels"
          />
        </Helmet>

        <p className="content-p">Philcomsat offers maritime, vessel-to-shore, or vessel-to-vessel mobile voice and data solutions for government, enterprise, or leisure vessels.</p>
        <br />

        <p className="content-p">You will benefit from:</p>
        <ul className="maritime-li">
          <li>Access to high-performance satellite beams across a much wider geographical area Global C-, Ka- and Ku-band capacity where and when you need it.</li>
          <li>Upcoming HTS capacity that will enable higher data throughputs at a lower cost per bit.</li>
          <li>Access to very high-throughput (greater than 1Gbps) and low-latency connectivity.</li>
          <li>Flexible, future-proof solutions compatible with new antenna designs.</li>
          <li>A dedicated team with a focus on enhancing the customer experience through new technologies 24/7 network operations center to ensure high quality of service.</li>
        </ul>

        <p className="content-p">If you need more information or would like a quote, please <Link to='/contact'>contact us</Link></p>
        <img className="services-img" src={process.env.PUBLIC_URL + '/images/services-maritime.jpg'} alt="Navy Ship" />
      </>
    );
  }


  renderDistanceLearning() {
    return (
      <>
        <Helmet>
          <title>Distance Learning Services</title>
          <meta
            name="description"
            content="Satellte solutions for schools"
          />
        </Helmet>

        <p className="content-p">Universities and Schools can take advantage of Philcomsat’s broadcast services through distance learning. Students in various locations in the archipelago can watch and listen to lectures of teachers and professors located in a central location. Because of television’s graphic nature, distance learning lecturers can give their students a more profound learning experience by adding multimedia elements to their lectures.</p>
        <br />

        <p className="content-p">If you need more information or would like a quote, please <Link to='/contact'>contact us</Link></p>
        <img className="services-img" src={process.env.PUBLIC_URL + '/images/services-distance-learning.jpg'} alt="Classroom" />
      </>
    );
  }


  renderBroadcastTV() {
    return (
      <>
        <Helmet>
          <title>Broadcast TV Services</title>
          <meta
            name="description"
            content="International and domestic television broadcast services"
          />
        </Helmet>

        <p className="content-p">Philcomsat plays an active role in the country’s international and domestic television broadcast services. News as they happen are transmitted via our facilities for special events or full-time program distribution of digital video and audio, in point to point or point to multi-point transmissions.</p>
        <br />

        <p className="content-p">Philcomsat’s international broadcast capability has made it the premier choice for special event broadcasts. Special events such as: Vatican Christmas Eve mass; Vatican Easter celebration; APEC summit, EDSA revolution, the Snap Elections as well as International broadcasts like Miss Universe Pageants utilized Philcomsat for its broadcast feeds.</p>
        <br />

        <p className="content-p">If you need more information or would like a quote, please <Link to='/contact'>contact us</Link></p>
      </>
    );
  }


  renderDisasterManagement() {
    return (
      <>
        <Helmet>
          <title>Disaster Management Services</title>
          <meta
            name="description"
            content="Portable VSAT solutions for corporate or military"
          />
        </Helmet>

        {/* <p className="content-p">Philcomsat offers a broad range of high performance portable fly-away VSAT satellite terminals and accessories to meet the needs of military, broadcast and corporate customers anywhere in the world. Our harsh environments applications expertise provides rugged and reliable satellite terminals to ensure your communication needs are met wherever you are.</p> */}
        <p className="content-p">
          Philcomsat is the service provider of choice of the National Disaster Risk Reduction and Management Council of the Office of Civil Defense to provide 
          communication during times of national disaster and crisis when ceullular phones and land lines are down.
        </p>
        <br />

        <p className="content-p">If you need more information or would like a quote, please <Link to='/contact'>contact us</Link></p>
        <img className="services-img" src={process.env.PUBLIC_URL + '/images/services-disaster-management.jpg'} alt="Flyaway Kit" />
      </>
    );
  }


  renderInternetVSAT() {
    return (
      <>
        <Helmet>
          <title>Internet Broadband VSAT Services</title>
          <meta
            name="description"
            content="Philcomsat is a leading Satellite Broadband VSAT Internet Provider for the Philippines."
          />
        </Helmet>

        <p className="content-p">Philcomsat offers high-speed internet access anywhere, anytime using C-, Ku-, and Ka-bands for government, enterprise, commercial or residential use.</p>
        <br/>
        <p className="content-p">In 2019, Philcomsat was chosen by Speedcast, Ltd., an Australian multinational company specializing in satellite communications services, to be its partner in implementing the “Free WIFI for All” project of the United Nations Development Programme and Department of Information and Communications Technology to install, operate and maintain free public internet service for 3,000 remote sites all over the Philippines using VSAT technology.</p>
        <br />

        <p className="content-p">If you need more information or would like a quote, please <Link to='/contact'>contact us</Link></p>
        <img className="services-img" src={process.env.PUBLIC_URL + '/images/services-vsat.jpg'} alt="VSAT Dish" />
      </>
    );
  }


  render() {
    return (
      <div className="services-container">
        <div className="hero-image-wrap">
          <img className={"hero-image " + this.deviceType} src={process.env.PUBLIC_URL + '/images/services-pinugay.jpg'} alt="Pinugay Earth Station" />
          <div className={"services-description " + this.deviceType}>SATELLITE SERVICES</div>
        </div>

        <div className="body-wrap-outer">
          <div className={"body-wrap-inner " + this.deviceType}>
            {this.renderSideBar()}
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}


export default Services;